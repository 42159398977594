var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter__wrapper" }, [
    _vm._m(0),
    _c("div", { staticClass: "filter__main" }, [
      _c("div", { staticClass: "filter__row" }, [
        _c(
          "div",
          { staticClass: "field__label" },
          [
            _c("div", { staticClass: "field__title" }, [_vm._v("Date")]),
            _c("Calendar", {
              attrs: { "is-range": "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var inputValue = ref.inputValue
                    var inputEvents = ref.inputEvents
                    return [
                      _c(
                        "input",
                        _vm._g(
                          {
                            staticClass: "field__input",
                            domProps: {
                              value: inputValue.start + " - " + inputValue.end
                            }
                          },
                          inputEvents.start
                        )
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.range,
                callback: function($$v) {
                  _vm.range = $$v
                },
                expression: "range"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "filter__row" },
        [
          _c("Select", {
            attrs: {
              options: _vm.shifts,
              label: "Shifts",
              placeholder: "Please select shifts"
            },
            model: {
              value: _vm.shift,
              callback: function($$v) {
                _vm.shift = $$v
              },
              expression: "shift"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter__row" },
        [
          _c("Select", {
            attrs: {
              options: _vm.services,
              label: "Services",
              placeholder: "Please select services"
            },
            model: {
              value: _vm.service,
              callback: function($$v) {
                _vm.service = $$v
              },
              expression: "service"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter__row" },
        [
          _c("Select", {
            attrs: {
              options: _vm.customers,
              label: "Customers",
              placeholder: "Please select customers"
            },
            model: {
              value: _vm.customer,
              callback: function($$v) {
                _vm.customer = $$v
              },
              expression: "customer"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter__row" },
        [
          _c("Select", {
            attrs: {
              options: _vm.statuses,
              label: "Statuses",
              placeholder: "Please select status"
            },
            model: {
              value: _vm.status,
              callback: function($$v) {
                _vm.status = $$v
              },
              expression: "status"
            }
          })
        ],
        1
      )
    ]),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "filter__header" }, [
      _c("div", { staticClass: "filter__title" }, [_vm._v("← Map Filters")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "filter__footer" }, [
      _c("div", { staticClass: "button" }, [_vm._v("Cancel")]),
      _c("div", { staticClass: "button button--fill" }, [_vm._v("Apply")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }