var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar__wrapper",
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          _vm.dialog = true
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "sidebar__inner",
          class: { "sidebar__inner--left": _vm.sidebarPosition === "left" },
          style: _vm.sidebarStyles
        },
        [
          _c(_vm.componentName, {
            tag: "component",
            attrs: { data: _vm.componentData }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "show-close": false,
            "close-on-click-modal": false,
            visible: _vm.dialog,
            modal: false,
            "custom-class": "dialog-close",
            width: "200px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialog = $event
            }
          }
        },
        [
          _c("span", [_vm._v("Close sidebar ?")]),
          _c(
            "div",
            { staticClass: "dialog__buttons" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.$root.$emit("ShowSidebar", null)
                    }
                  }
                },
                [_vm._v("Yes")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("No")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }