var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasMultipleIds
    ? _c("div", { staticClass: "sidebar__main" }, [
        _c("h2", [_vm._v("Select a delivery")]),
        _c(
          "ul",
          { staticClass: "list-unstyled" },
          _vm._l(_vm.data.ids, function(id) {
            return _c(
              "li",
              {
                key: id,
                on: {
                  click: function($event) {
                    return _vm.selectDelivery(id)
                  }
                }
              },
              [_vm._v("Route #" + _vm._s(id))]
            )
          }),
          0
        )
      ])
    : _vm.isLoaded
    ? _c("div", { staticClass: "sidebar__main" }, [
        _c("div", { staticClass: "sidebar__title" }, [
          _c("span", [_vm._v("Route #" + _vm._s(_vm.id))])
        ]),
        _c(
          "div",
          { staticClass: "sidebar__main-inner" },
          [
            _c("h2", [_vm._v("Status:")]),
            _vm.delivery.status
              ? _c("span", [_vm._v("Done")])
              : _c("span", [_vm._v("Not Done")]),
            _c("h2", [_vm._v("Info:")]),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", [
                _vm._v(
                  "Route Date - " +
                    _vm._s(
                      new Date(_vm.delivery.routeDate)
                        .toISOString()
                        .split("T")[0]
                    )
                )
              ]),
              _c("li", [_vm._v("Billing - " + _vm._s(_vm.delivery.billing))]),
              _c("li", [
                _vm._v(
                  "Service - " +
                    _vm._s(_vm.delivery.serviceName) +
                    " (ServiceID: " +
                    _vm._s(_vm.delivery.serviceID) +
                    ")"
                )
              ])
            ]),
            _c("h2", [_vm._v("Customers:")]),
            _vm._l(_vm.delivery.customers, function(customer) {
              return _c(
                "ul",
                { key: customer.id, staticClass: "list-unstyled" },
                [
                  _vm._v(
                    " --------------------------------------------------- "
                  ),
                  _c("li", [
                    _vm._v(
                      " Status - " +
                        _vm._s(customer.status ? "Done" : "Not Done") +
                        " "
                    ),
                    !customer.status
                      ? _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.markDone(customer.id)
                              }
                            }
                          },
                          [_vm._v("Done!")]
                        )
                      : _vm._e()
                  ]),
                  _c("li", [_vm._v("name - " + _vm._s(customer.customerName))]),
                  _c("li", [_vm._v("type - " + _vm._s(customer.type))]),
                  _c("li", [
                    _vm._v(
                      " address - " +
                        _vm._s(
                          [
                            customer.address,
                            customer.city,
                            customer.province,
                            customer.postalCode
                          ]
                            .filter(function(v) {
                              return !!v
                            })
                            .join(", ")
                        ) +
                        " "
                    )
                  ]),
                  _c("li", [_vm._v("notes - " + _vm._s(customer.notes))])
                ]
              )
            })
          ],
          2
        ),
        _c("div", { staticClass: "sidebar__footer" }, [
          _c("div", { staticClass: "sidebar__footer-inner" }, [
            _c(
              "div",
              { staticClass: "button", on: { click: _vm.closeSidebar } },
              [_vm._v("Close")]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }