var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "superAdmin-layout" },
    [
      _c("Navbar"),
      _c("router-view"),
      _vm.sidebarName
        ? _c("Sidebar", {
            attrs: {
              "component-name": _vm.sidebarName,
              "component-styles": _vm.componentStyles,
              "sidebar-styles": _vm.sidebarStyles,
              "sidebar-position": _vm.sidebarPosition,
              "component-data": _vm.componentData
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }