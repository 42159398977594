var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map__wrapper" }, [
    _c("div", { ref: "map", staticClass: "map__inner" }),
    _c(
      "button",
      {
        staticClass: "button button--fill",
        on: {
          click: function($event) {
            return _vm.$modal.hide("Map")
          }
        }
      },
      [_vm._v("Save Coords")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }