var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar__main" }, [
    _c("div", { staticClass: "sidebar__title" }, [
      _vm.data
        ? _c("span", [_vm._v("Edit - " + _vm._s(_vm.data.name))])
        : _c("span", [_vm._v("Add New Company")])
    ]),
    _c("div", { staticClass: "sidebar__main-inner" }, [
      _c("div", { staticClass: "sidebar__row" }, [
        _c("div", { staticClass: "sidebar__row-title" }, [
          _vm._v("Basic Info")
        ]),
        _c(
          "div",
          { staticClass: "sidebar__row-main sidebar__row-main--half" },
          [
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.name,
                  "field__label--invalid":
                    _vm.$v.sendData.name.$dirty &&
                    !_vm.$v.sendData.name.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [_vm._v("Name")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.name.$model,
                      expression: "$v.sendData.name.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Name" },
                  domProps: { value: _vm.$v.sendData.name.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.name,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.phone,
                  "field__label--invalid":
                    (_vm.$v.sendData.phone.$dirty &&
                      !_vm.$v.sendData.phone.required) ||
                    (_vm.$v.sendData.phone.$dirty &&
                      !_vm.$v.sendData.phone.phoneReg)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [_vm._v("Phone")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.phone.$model,
                      expression: "$v.sendData.phone.$model"
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "### ### ####",
                      expression: "'### ### ####'"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "tel", placeholder: "Phone" },
                  domProps: { value: _vm.$v.sendData.phone.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.phone,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.address,
                  "field__label--invalid":
                    _vm.$v.sendData.address.$dirty &&
                    !_vm.$v.sendData.address.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [_vm._v("Address")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.address.$model,
                      expression: "$v.sendData.address.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Phone" },
                  domProps: { value: _vm.$v.sendData.address.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.address,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.city,
                  "field__label--invalid":
                    _vm.$v.sendData.city.$dirty &&
                    !_vm.$v.sendData.city.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [_vm._v("City")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.city.$model,
                      expression: "$v.sendData.city.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "City" },
                  domProps: { value: _vm.$v.sendData.city.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.city,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.province,
                  "field__label--invalid":
                    _vm.$v.sendData.province.$dirty &&
                    !_vm.$v.sendData.province.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Province")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.province.$model,
                      expression: "$v.sendData.province.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Province" },
                  domProps: { value: _vm.$v.sendData.province.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.province,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.country,
                  "field__label--invalid":
                    _vm.$v.sendData.country.$dirty &&
                    !_vm.$v.sendData.country.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [_vm._v("Country")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.country.$model,
                      expression: "$v.sendData.country.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Country" },
                  domProps: { value: _vm.$v.sendData.country.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.country,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.postalCode,
                  "field__label--invalid":
                    _vm.$v.sendData.postalCode.$dirty &&
                    !_vm.$v.sendData.postalCode.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Postal Code")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.postalCode.$model,
                      expression: "$v.sendData.postalCode.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "tel", placeholder: "Postal Code" },
                  domProps: { value: _vm.$v.sendData.postalCode.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.postalCode,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.hst,
                  "field__label--invalid":
                    (_vm.$v.sendData.hst.$dirty &&
                      !_vm.$v.sendData.hst.required) ||
                    (_vm.$v.sendData.hst.$dirty &&
                      !_vm.$v.sendData.hst.minLength)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [_vm._v("HST")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.hst.$model,
                      expression: "$v.sendData.hst.$model"
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "########",
                      expression: "'########'"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "HST" },
                  domProps: { value: _vm.$v.sendData.hst.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.hst,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.sendData.website && _vm.$v.sendData.website.url,
                  "field__label--invalid":
                    _vm.$v.sendData.website.$dirty &&
                    !_vm.$v.sendData.website.url
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [_vm._v("Website")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.website.$model,
                      expression: "$v.sendData.website.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Website" },
                  domProps: { value: _vm.$v.sendData.website.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.website,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          ]
        )
      ]),
      !_vm.data
        ? _c("div", { staticClass: "sidebar__row" }, [
            _c("div", { staticClass: "sidebar__row-title" }, [
              _vm._v("Contact Info")
            ]),
            _c(
              "div",
              { staticClass: "sidebar__row-main sidebar__row-main--half" },
              [
                _c(
                  "label",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled": _vm.sendData.admin.name,
                      "field__label--invalid":
                        _vm.$v.sendData.admin.name.$dirty &&
                        !_vm.$v.sendData.admin.name.required
                    }
                  },
                  [
                    _c("div", { staticClass: "field__title" }, [
                      _vm._v("Name")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.sendData.admin.name.$model,
                          expression: "$v.sendData.admin.name.$model"
                        }
                      ],
                      staticClass: "field__input",
                      attrs: { type: "text", placeholder: "Name" },
                      domProps: { value: _vm.$v.sendData.admin.name.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.sendData.admin.name,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled": _vm.sendData.admin.email,
                      "field__label--invalid":
                        (_vm.$v.sendData.admin.email.$dirty &&
                          !_vm.$v.sendData.admin.email.required) ||
                        (_vm.$v.sendData.admin.email.$dirty &&
                          !_vm.$v.sendData.admin.email.email)
                    }
                  },
                  [
                    _c("div", { staticClass: "field__title" }, [
                      _vm._v("Email")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.sendData.admin.email.$model,
                          expression: "$v.sendData.admin.email.$model"
                        }
                      ],
                      staticClass: "field__input",
                      attrs: { type: "text", placeholder: "Email" },
                      domProps: { value: _vm.$v.sendData.admin.email.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.sendData.admin.email,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled": _vm.sendData.admin.password,
                      "field__label--invalid":
                        (_vm.$v.sendData.admin.password.$dirty &&
                          !_vm.$v.sendData.admin.password.required) ||
                        (_vm.$v.sendData.admin.password.$dirty &&
                          !_vm.$v.sendData.admin.password.minLength)
                    }
                  },
                  [
                    _c("div", { staticClass: "field__title" }, [
                      _vm._v("Password")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.sendData.admin.password.$model,
                          expression: "$v.sendData.admin.password.$model"
                        }
                      ],
                      staticClass: "field__input",
                      attrs: { type: "text", placeholder: "Password" },
                      domProps: {
                        value: _vm.$v.sendData.admin.password.$model
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.sendData.admin.password,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                )
              ]
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "sidebar__row sidebar__row--last" }, [
        _c("div", { staticClass: "sidebar__row-title" }, [_vm._v("Extras")]),
        _c(
          "div",
          { staticClass: "sidebar__row-main sidebar__row-main--half" },
          [
            _c(
              "label",
              { staticClass: "field__label field__label--sides" },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Fuel Surcharge")
                ]),
                _c("el-switch", {
                  model: {
                    value: _vm.sendData.fuelSuperCharge,
                    callback: function($$v) {
                      _vm.$set(_vm.sendData, "fuelSuperCharge", $$v)
                    },
                    expression: "sendData.fuelSuperCharge"
                  }
                })
              ],
              1
            ),
            _c(
              "label",
              { staticClass: "field__label field__label--sides" },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Public Customers Access")
                ]),
                _c("el-switch", {
                  model: {
                    value: _vm.sendData.canAccessPublicCustomers,
                    callback: function($$v) {
                      _vm.$set(_vm.sendData, "canAccessPublicCustomers", $$v)
                    },
                    expression: "sendData.canAccessPublicCustomers"
                  }
                })
              ],
              1
            )
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "sidebar__footer" }, [
      _c("div", { staticClass: "sidebar__footer-inner" }, [
        _c(
          "div",
          {
            staticClass: "button button--fill",
            on: { click: _vm.createCompanies }
          },
          [
            _vm.data
              ? _c("span", [_vm._v("Save Changes")])
              : _c("span", [_vm._v("Create Company")])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "button",
            on: {
              click: function($event) {
                return _vm.$root.$emit("ShowSidebar", null)
              }
            }
          },
          [_vm._v("Cancel")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }