var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar__wrapper" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "navbar__inner" }, [
        _vm._m(0),
        _c("div", { staticClass: "navbar__center" }, [
          _c("nav", { staticClass: "navbar__navigation" }, [
            _c(
              "ul",
              { staticClass: "nav__list" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav__item",
                    attrs: {
                      to: "/companies",
                      "active-class": "nav__item--active"
                    }
                  },
                  [
                    _c("i", { staticClass: "ri-truck-fill" }),
                    _c("span", [_vm._v("Companies")])
                  ]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "nav__item",
                    attrs: {
                      to: "/clients",
                      "active-class": "nav__item--active"
                    }
                  },
                  [
                    _c("i", { staticClass: "ri-user-2-fill" }),
                    _c("span", [_vm._v("Public Clients")])
                  ]
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "navbar__right" }, [
          _c("div", { staticClass: "letter navbar__letter" }, [
            _vm._v(_vm._s(_vm.nameFirstLetter))
          ]),
          _c("div", { staticClass: "navbar__name" }, [
            _vm._v(_vm._s(_vm.$store.getters.user.name))
          ]),
          _c(
            "div",
            { staticClass: "navbar__actions" },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" }, on: { command: _vm.action } },
                [
                  _c("i", { staticClass: "ri-more-fill" }),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--actions",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          attrs: { command: "Logout" }
                        },
                        [
                          _c("i", { staticClass: "ri-logout-circle-line" }),
                          _vm._v(" Logout ")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "navbar__mobile" },
        [
          _c(
            "el-dropdown",
            {
              attrs: { trigger: "click", placement: "bottom-start" },
              on: { "visible-change": _vm.showDropBg }
            },
            [
              _c("button", { staticClass: "icon-button" }, [
                _c("i", { staticClass: "ri-menu-line" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "el-popper-custom el-popper-custom--nav",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "el-dropdown-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav__item--mobile",
                          attrs: {
                            to: "/companies",
                            "active-class": "nav__item--active"
                          }
                        },
                        [
                          _c("i", { staticClass: "ri-truck-fill" }),
                          _c("span", [_vm._v("Companies")])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav__item--mobile",
                          attrs: {
                            to: "/clients",
                            "active-class": "nav__item--active"
                          }
                        },
                        [
                          _c("i", { staticClass: "ri-user-2-fill" }),
                          _c("span", [_vm._v("Public Clients")])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "navbar__title" }, [
            _vm._v(_vm._s(_vm.path.name))
          ]),
          _c(
            "el-dropdown",
            {
              staticClass: "end",
              attrs: { trigger: "click" },
              on: { command: _vm.action }
            },
            [
              _c("button", { staticClass: "icon-button icon-button--round" }, [
                _c("i", { staticClass: "ri-more-fill" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "el-popper-custom",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "el-dropdown-item",
                    { staticClass: "item--edit", attrs: { command: "Logout" } },
                    [
                      _c("i", { staticClass: "ri-logout-circle-line" }),
                      _vm._v(" Logout ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showBg ? _c("div", { staticClass: "mobile-bg" }) : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navbar__left" }, [
      _c("div", { staticClass: "navbar__title" }, [_vm._v(" STS App ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }