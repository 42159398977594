var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar__main" }, [
    _c("div", { staticClass: "sidebar__title" }, [
      _vm.data
        ? _c("span", [_vm._v("Edit - " + _vm._s(_vm.data.businessName))])
        : _c("span", [_vm._v("Add New Customer")])
    ]),
    _c("div", { staticClass: "sidebar__main-inner" }, [
      _c("div", { staticClass: "sidebar__row" }, [
        _c("div", { staticClass: "sidebar__row-title" }, [
          _vm._v("Basic Data")
        ]),
        _c(
          "div",
          { staticClass: "sidebar__row-main sidebar__row-main--half" },
          [
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.businessName.$model &&
                    _vm.$v.sendData.businessName.$dirty,
                  "field__label--invalid":
                    _vm.$v.sendData.businessName.$dirty &&
                    !_vm.$v.sendData.businessName.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Business Name")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.businessName.$model,
                      expression: "$v.sendData.businessName.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Business Name" },
                  domProps: { value: _vm.$v.sendData.businessName.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.businessName,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.contactEmail.$model &&
                    _vm.$v.sendData.contactEmail.email &&
                    _vm.$v.sendData.contactEmail.$dirty,
                  "field__label--invalid":
                    _vm.$v.sendData.contactEmail.$dirty &&
                    (!_vm.$v.sendData.contactEmail.required ||
                      !_vm.$v.sendData.contactEmail.email)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Contact Email")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.contactEmail.$model,
                      expression: "$v.sendData.contactEmail.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Contact Email" },
                  domProps: { value: _vm.$v.sendData.contactEmail.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.contactEmail,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.firstName.$model &&
                    _vm.$v.sendData.firstName.$dirty,
                  "field__label--invalid":
                    _vm.$v.sendData.firstName.$dirty &&
                    !_vm.$v.sendData.firstName.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("First Name")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.firstName.$model,
                      expression: "$v.sendData.firstName.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "First Name" },
                  domProps: { value: _vm.$v.sendData.firstName.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.firstName,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.lastName.$model &&
                    _vm.$v.sendData.lastName.$dirty,
                  "field__label--invalid":
                    _vm.$v.sendData.lastName.$dirty &&
                    !_vm.$v.sendData.lastName.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Last Name")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.lastName.$model,
                      expression: "$v.sendData.lastName.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Last Name" },
                  domProps: { value: _vm.$v.sendData.lastName.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.lastName,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.nickName.$model &&
                    _vm.$v.sendData.nickName.$dirty
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Nick Name")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.nickName.$model,
                      expression: "$v.sendData.nickName.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Nick Name" },
                  domProps: { value: _vm.$v.sendData.nickName.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.nickName,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.middleInitial.$model &&
                    _vm.$v.sendData.middleInitial.$dirty
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Middle Initial")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.middleInitial.$model,
                      expression: "$v.sendData.middleInitial.$model"
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "AA",
                      expression: "'AA'"
                    }
                  ],
                  staticClass: "field__input field__input--uppercase",
                  attrs: { type: "text", placeholder: "MI" },
                  domProps: { value: _vm.$v.sendData.middleInitial.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.middleInitial,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "sidebar__row" }, [
        _c("div", { staticClass: "sidebar__row-title" }, [
          _vm._v("Contact Info")
        ]),
        _c(
          "div",
          { staticClass: "sidebar__row-main sidebar__row-main--half" },
          [
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.cellPhone.$model &&
                    _vm.$v.sendData.cellPhone.$dirty &&
                    _vm.$v.sendData.cellPhone.phoneReg,
                  "field__label--invalid":
                    (_vm.$v.sendData.cellPhone.$dirty &&
                      !_vm.$v.sendData.cellPhone.required) ||
                    (_vm.$v.sendData.cellPhone.$dirty &&
                      !_vm.$v.sendData.cellPhone.phoneReg)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Cell Phone")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.cellPhone.$model,
                      expression: "$v.sendData.cellPhone.$model"
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "### ### ####",
                      expression: "'### ### ####'"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Cell Phone" },
                  domProps: { value: _vm.$v.sendData.cellPhone.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.cellPhone,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.homePhone.$model &&
                    _vm.$v.sendData.homePhone.$dirty &&
                    _vm.$v.sendData.homePhone.phoneReg,
                  "field__label--invalid":
                    (_vm.$v.sendData.homePhone.$dirty &&
                      !_vm.$v.sendData.homePhone.required) ||
                    (_vm.$v.sendData.homePhone.$dirty &&
                      !_vm.$v.sendData.homePhone.phoneReg)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Home Phone")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.homePhone.$model,
                      expression: "$v.sendData.homePhone.$model"
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "### ### ####",
                      expression: "'### ### ####'"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Home Phone" },
                  domProps: { value: _vm.$v.sendData.homePhone.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.homePhone,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.businessPhone.$model &&
                    _vm.$v.sendData.businessPhone.$dirty &&
                    _vm.$v.sendData.businessPhone.phoneReg,
                  "field__label--invalid":
                    (_vm.$v.sendData.businessPhone.$dirty &&
                      !_vm.$v.sendData.businessPhone.required) ||
                    (_vm.$v.sendData.businessPhone.$dirty &&
                      !_vm.$v.sendData.businessPhone.phoneReg)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Business Phone")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.businessPhone.$model,
                      expression: "$v.sendData.businessPhone.$model"
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "### ### ####",
                      expression: "'### ### ####'"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Business Phone" },
                  domProps: { value: _vm.$v.sendData.businessPhone.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.businessPhone,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.accountingEmail.$model &&
                    _vm.$v.sendData.accountingEmail.$dirty &&
                    _vm.$v.sendData.accountingEmail.email,
                  "field__label--invalid":
                    (_vm.$v.sendData.accountingEmail.$dirty &&
                      !_vm.$v.sendData.accountingEmail.required) ||
                    (_vm.$v.sendData.accountingEmail.$dirty &&
                      !_vm.$v.sendData.accountingEmail.email)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Accounting E-mail")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.accountingEmail.$model,
                      expression: "$v.sendData.accountingEmail.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Accounting E-mail" },
                  domProps: { value: _vm.$v.sendData.accountingEmail.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.accountingEmail,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "sidebar__row" }, [
        _c("div", { staticClass: "sidebar__row-title" }, [
          _vm._v("Address Info")
        ]),
        _c(
          "div",
          { staticClass: "sidebar__row-main sidebar__row-main--half" },
          [
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.lat.$model && _vm.$v.sendData.lat.$dirty,
                  "field__label--invalid":
                    _vm.$v.sendData.lat.$dirty && !_vm.$v.sendData.lat.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Latitude")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.lat.$model,
                      expression: "$v.sendData.lat.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Latitude" },
                  domProps: { value: _vm.$v.sendData.lat.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.lat,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.lng.$model && _vm.$v.sendData.lng.$dirty,
                  "field__label--invalid":
                    _vm.$v.sendData.lng.$dirty && !_vm.$v.sendData.lng.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Longitude")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.lng.$model,
                      expression: "$v.sendData.lng.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Longitude" },
                  domProps: { value: _vm.$v.sendData.lng.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.lng,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.country.$model &&
                    _vm.$v.sendData.country.$dirty,
                  "field__label--invalid":
                    _vm.$v.sendData.country.$dirty &&
                    !_vm.$v.sendData.country.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [_vm._v("Country")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.country.$model,
                      expression: "$v.sendData.country.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Country" },
                  domProps: { value: _vm.$v.sendData.country.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.country,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.province.$model &&
                    _vm.$v.sendData.province.$dirty,
                  "field__label--invalid":
                    _vm.$v.sendData.province.$dirty &&
                    !_vm.$v.sendData.province.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Province")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.province.$model,
                      expression: "$v.sendData.province.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Province" },
                  domProps: { value: _vm.$v.sendData.province.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.province,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.city.$model && _vm.$v.sendData.city.$dirty,
                  "field__label--invalid":
                    _vm.$v.sendData.city.$dirty &&
                    !_vm.$v.sendData.city.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [_vm._v("City")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.city.$model,
                      expression: "$v.sendData.city.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "City" },
                  domProps: { value: _vm.$v.sendData.city.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.city,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.postalCode.$model &&
                    _vm.$v.sendData.postalCode.$dirty,
                  "field__label--invalid":
                    (_vm.$v.sendData.postalCode.$dirty &&
                      !_vm.$v.sendData.postalCode.required) ||
                    (_vm.$v.sendData.postalCode.$dirty &&
                      !_vm.$v.sendData.postalCode.minLength)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Postal Code")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.postalCode.$model,
                      expression: "$v.sendData.postalCode.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Postal Code" },
                  domProps: { value: _vm.$v.sendData.postalCode.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.postalCode,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.northSouth.$model &&
                    _vm.$v.sendData.northSouth.$dirty,
                  "field__label--invalid":
                    _vm.$v.sendData.northSouth.$dirty &&
                    !_vm.$v.sendData.northSouth.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("North/South")
                ]),
                _c("el-switch", {
                  staticClass: "el-switch--field",
                  attrs: { "active-text": "North", "inactive-text": "South" },
                  model: {
                    value: _vm.$v.sendData.northSouth.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.sendData.northSouth, "$model", $$v)
                    },
                    expression: "$v.sendData.northSouth.$model"
                  }
                })
              ],
              1
            ),
            _c(
              "label",
              {
                staticClass: "field__label field__label--full",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.address.$model &&
                    _vm.$v.sendData.address.$dirty,
                  "field__label--invalid":
                    _vm.$v.sendData.address.$dirty &&
                    !_vm.$v.sendData.address.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [_vm._v("Address")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.address.$model,
                      expression: "$v.sendData.address.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Address" },
                  domProps: { value: _vm.$v.sendData.address.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.address,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          ]
        )
      ]),
      _c(
        "div",
        {
          staticClass: "sidebar__row",
          class: { "sidebar__row--last": !_vm.isEnterprise }
        },
        [
          _c("div", { staticClass: "sidebar__row-title" }, [
            _vm._v("User Info")
          ]),
          _c("div", { staticClass: "sidebar__row-main" }, [
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.sendData.user.password.$model &&
                    _vm.$v.sendData.user.password.$dirty &&
                    _vm.$v.sendData.user.password.minLength,
                  "field__label--invalid":
                    (_vm.$v.sendData.user.password.$dirty &&
                      !_vm.$v.sendData.user.password.required) ||
                    (_vm.$v.sendData.user.password.$dirty &&
                      !_vm.$v.sendData.user.password.minLength)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Password")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sendData.user.password,
                      expression: "sendData.user.password"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Password" },
                  domProps: { value: _vm.sendData.user.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.sendData.user,
                        "password",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          ])
        ]
      ),
      _vm.isEnterprise
        ? _c("div", { staticClass: "sidebar__row sidebar__row--last" }, [
            _c(
              "div",
              { staticClass: "sidebar__row-main sidebar__row-main--half" },
              [
                _c(
                  "label",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled":
                        _vm.$v.sendData.accountingName.$model &&
                        _vm.$v.sendData.accountingName.$dirty,
                      "field__label--invalid":
                        _vm.$v.sendData.accountingName.$dirty &&
                        !_vm.$v.sendData.accountingName.required
                    }
                  },
                  [
                    _c("div", { staticClass: "field__title" }, [
                      _vm._v("Accounting Name")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.sendData.accountingName.$model,
                          expression: "$v.sendData.accountingName.$model"
                        }
                      ],
                      staticClass: "field__input",
                      attrs: { type: "text", placeholder: "Accounting Name" },
                      domProps: {
                        value: _vm.$v.sendData.accountingName.$model
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.sendData.accountingName,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass: "field__label",
                    class: {
                      "field__label--filled":
                        _vm.$v.sendData.accountingPhone.$model &&
                        _vm.$v.sendData.accountingPhone.$dirty &&
                        _vm.$v.sendData.accountingPhone.phoneReg,
                      "field__label--invalid":
                        (_vm.$v.sendData.accountingPhone.$dirty &&
                          !_vm.$v.sendData.accountingPhone.required) ||
                        (_vm.$v.sendData.accountingPhone.$dirty &&
                          !_vm.$v.sendData.accountingPhone.phoneReg)
                    }
                  },
                  [
                    _c("div", { staticClass: "field__title" }, [
                      _vm._v("Accounting Phone")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.sendData.accountingPhone.$model,
                          expression: "$v.sendData.accountingPhone.$model"
                        },
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "### ### ####",
                          expression: "'### ### ####'"
                        }
                      ],
                      staticClass: "field__input",
                      attrs: { type: "text", placeholder: "Accounting Phone" },
                      domProps: {
                        value: _vm.$v.sendData.accountingPhone.$model
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.sendData.accountingPhone,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "sidebar__row-main" }, [
              _c("div", { staticClass: "sidebar__employees" }, [
                _c("div", { staticClass: "field__label-employees" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newEmployee,
                        expression: "newEmployee"
                      }
                    ],
                    staticClass: "field__input",
                    attrs: { type: "text", placeholder: "New Employee" },
                    domProps: { value: _vm.newEmployee },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.newEmployee = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "button button--big",
                      on: { click: _vm.addEmployee }
                    },
                    [_c("i", { staticClass: "ri-add-circle-fill" })]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "sidebar__employees-list" },
                  _vm._l(_vm.sendData.employeeNames, function(empl, idx) {
                    return _c(
                      "div",
                      {
                        key: empl + idx,
                        staticClass: "sidebar__employees-item"
                      },
                      [
                        _c("span", [_vm._v(_vm._s(empl))]),
                        _c(
                          "button",
                          { staticClass: "icon-button icon-button--round" },
                          [
                            _c("i", {
                              staticClass: "ri-delete-bin-7-fill",
                              on: {
                                click: function($event) {
                                  return _vm.delEmployee(empl)
                                }
                              }
                            })
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "sidebar__footer" }, [
      _c("div", { staticClass: "sidebar__footer-inner" }, [
        _c(
          "div",
          {
            staticClass: "button button--fill",
            on: { click: _vm.createCustomer }
          },
          [_vm._v("Save")]
        ),
        _c(
          "div",
          {
            staticClass: "button",
            on: {
              click: function($event) {
                return _vm.$root.$emit("ShowSidebar", null)
              }
            }
          },
          [_vm._v("Cancel")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }