var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login__wrapper" },
    [
      _c("router-view"),
      _c("div", { staticClass: "login__bg" }),
      _c("div", {
        staticClass: "login__bg",
        class: { "login__bg--blur": _vm.isBlur }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }