var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__wrapper" }, [
    _vm._m(0),
    _c("div", { staticClass: "onboarding__wrapper" }, [
      _c("div", { staticClass: "onboarding__header" }, [
        _c("div", { staticClass: "onboarding__step" }, [
          _vm._v(_vm._s(_vm.step))
        ]),
        _c("div", { staticClass: "onboarding__title" }, [
          _vm._v(_vm._s(_vm.stepText))
        ]),
        _c("div", { staticClass: "onboarding__subtitle" }, [
          _vm._v("Book Order with STS")
        ])
      ]),
      _c("div", { staticClass: "onboarding__main" }, [
        _vm.step === 1
          ? _c("div", { staticClass: "onboarding__message" }, [
              _c(
                "div",
                {
                  staticClass:
                    "onboarding__message-icon onboarding__message-icon--red"
                },
                [_vm._v("i")]
              ),
              _c("div", { staticClass: "onboarding__message-text" }, [
                _vm._v(
                  " Temporarily, if you book the order you must pay. Third party billing will be available soon. "
                )
              ])
            ])
          : _vm._e(),
        _vm.step === 1
          ? _c("div", { staticClass: "onboarding__message" }, [
              _c("div", { staticClass: "onboarding__message-icon" }, [
                _vm._v("i")
              ]),
              _vm._m(1)
            ])
          : _vm._e(),
        _vm.step === 2
          ? _c("div", { staticClass: "onboarding__message" }, [
              _c("div", { staticClass: "onboarding__message-icon" }, [
                _vm._v("i")
              ]),
              _c("div", { staticClass: "onboarding__message-text" }, [
                _vm._v(
                  " Adding a picture is a great way to make the driver knows exactly what they are looking for. "
                )
              ])
            ])
          : _vm._e(),
        _vm.step === 3
          ? _c("div", { staticClass: "onboarding__message" }, [
              _c(
                "div",
                {
                  staticClass:
                    "onboarding__message-icon onboarding__message-icon--green"
                },
                [_vm._v("i")]
              ),
              _c("div", { staticClass: "onboarding__message-text" }, [
                _vm._v(" Debit cards are accepted. ")
              ])
            ])
          : _vm._e(),
        _vm.step === 3
          ? _c("div", { staticClass: "onboarding__message" }, [
              _c("div", { staticClass: "onboarding__message-icon" }, [
                _vm._v("i")
              ]),
              _c("div", { staticClass: "onboarding__message-text" }, [
                _vm._v(" Save as many debit & credit cards as you want. ")
              ])
            ])
          : _vm._e(),
        _vm.step === 4
          ? _c("div", { staticClass: "onboarding__message" }, [
              _c("div", { staticClass: "onboarding__message-icon" }, [
                _vm._v("i")
              ]),
              _c("div", { staticClass: "onboarding__message-text" }, [
                _vm._v(
                  " To keep your book keeping organized, add a payment method for personal, the farm, and the shop. "
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "onboarding__footer" }, [
        _vm.step === 4 && !_vm.helpModal
          ? _c(
              "div",
              { staticClass: "onboarding__switch" },
              [
                _c("div", { staticClass: "onboarding__switch-text" }, [
                  _vm._v("Do not show tutorial again")
                ]),
                _c("el-switch", {
                  model: {
                    value: _vm.showSteps,
                    callback: function($$v) {
                      _vm.showSteps = $$v
                    },
                    expression: "showSteps"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "button button--fill onboarding__button",
            class: { "onboarding__button--green": _vm.step === 4 },
            on: { click: _vm.nextStep }
          },
          [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
        ),
        _c("div", { staticClass: "onboarding__dots" }, [
          _c("div", {
            staticClass: "onboarding__dot",
            class: { "onboarding__dot--blue": _vm.step === 1 }
          }),
          _c("div", {
            staticClass: "onboarding__dot",
            class: { "onboarding__dot--blue": _vm.step === 2 }
          }),
          _c("div", {
            staticClass: "onboarding__dot",
            class: { "onboarding__dot--blue": _vm.step === 3 }
          }),
          _c("div", {
            staticClass: "onboarding__dot",
            class: { "onboarding__dot--green": _vm.step === 4 }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "onboarding__gif-wrapper" }, [
      _c("div", { staticClass: "onboarding__gif-inner" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "onboarding__message-text" }, [
      _vm._v(" Use the button "),
      _c("span", { staticClass: "onboarding__button--info" }, [
        _vm._v("choose me")
      ]),
      _vm._v(" to quickly select yourself as the pick up or drop off. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }