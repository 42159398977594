var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar__wrapper" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "navbar__inner" }, [
        _vm._m(0),
        _c("div", [
          _c("nav", { staticClass: "navbar__navigation" }, [
            _c(
              "ul",
              { staticClass: "nav__list" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav__item",
                    attrs: { to: "/map", "active-class": "nav__item--active" }
                  },
                  [
                    _c("i", { staticClass: "ri-map-pin-fill" }),
                    _c("span", [_vm._v("Map")])
                  ]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "nav__item",
                    attrs: {
                      to: "/deliveries",
                      "exact-active-class": "nav__item--active"
                    }
                  },
                  [
                    _c("i", { staticClass: "ri-pin-distance-fill" }),
                    _c("span", [_vm._v("Deliveries")])
                  ]
                ),
                _vm.role !== "driver"
                  ? _c(
                      "router-link",
                      {
                        staticClass: "nav__item",
                        attrs: {
                          to: "/users",
                          "active-class": "nav__item--active"
                        }
                      },
                      [
                        _c("i", { staticClass: "ri-user-3-line" }),
                        _c("span", [_vm._v("Users")])
                      ]
                    )
                  : _vm._e(),
                _vm.role !== "driver"
                  ? _c(
                      "router-link",
                      {
                        staticClass: "nav__item",
                        attrs: {
                          to: "/services",
                          "active-class": "nav__item--active"
                        }
                      },
                      [
                        _c("i", { staticClass: "ri-stack-fill" }),
                        _c("span", [_vm._v("Services")])
                      ]
                    )
                  : _vm._e(),
                _vm.role !== "driver"
                  ? _c(
                      "router-link",
                      {
                        staticClass: "nav__item",
                        attrs: {
                          to: "/shifts",
                          "active-class": "nav__item--active"
                        }
                      },
                      [
                        _c("i", { staticClass: "ri-focus-line" }),
                        _c("span", [_vm._v("Shifts")])
                      ]
                    )
                  : _vm._e(),
                _vm.role !== "driver"
                  ? _c(
                      "router-link",
                      {
                        staticClass: "nav__item",
                        attrs: {
                          to: "/dashboard",
                          "active-class": "nav__item--active"
                        }
                      },
                      [
                        _c("i", { staticClass: "ri-donut-chart-line" }),
                        _c("span", [_vm._v("Dashboard")])
                      ]
                    )
                  : _vm._e(),
                _c(
                  "router-link",
                  {
                    staticClass: "nav__item",
                    attrs: {
                      to: "/orders",
                      "active-class": "nav__item--active"
                    }
                  },
                  [
                    _c("i", { staticClass: "ri-map-pin-fill" }),
                    _c("span", [_vm._v("Orders")])
                  ]
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "navbar__right" }, [
          _vm.hasBlock && _vm.hasBlock.isActive
            ? _c(
                "div",
                {
                  staticClass: "navbar__ostatus",
                  on: { click: _vm.changeBypassed }
                },
                [
                  _vm.lockBtn
                    ? _c("i", { staticClass: "ri-lock-unlock-line" })
                    : _c("i", { staticClass: "ri-lock-line" })
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "navbar__notification" },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click", placement: "bottom-end" },
                  on: { command: _vm.openNotification }
                },
                [
                  _c("div", { staticClass: "navbar__notification-button" }, [
                    _c("i", { staticClass: "ri-notification-2-fill" }),
                    _vm.notificatonCounter
                      ? _c(
                          "div",
                          { staticClass: "navbar__notification-number" },
                          [_vm._v(_vm._s(_vm.notificatonCounter))]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--notifications",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      !_vm.notifications.length
                        ? _c(
                            "el-dropdown-item",
                            { staticClass: "item__notification--missing" },
                            [_vm._v(" No notifications 😴 ")]
                          )
                        : _vm._e(),
                      _vm._l(_vm.notifications, function(not) {
                        return _c(
                          "el-dropdown-item",
                          {
                            key: not.id,
                            staticClass: "item__notification",
                            class: {
                              "item__notification--active": !not.wasRead
                            },
                            attrs: { command: not }
                          },
                          [
                            _c("div", {
                              staticClass: "dot",
                              class: {
                                "dot--green":
                                  not.status === "PENDING" || !not.wasRead,
                                "dot--red": not.status === "CANCELED",
                                "dot--yellow": not.wasRead
                              }
                            }),
                            not.status === "PENDING"
                              ? _c("div", [
                                  _vm._v(
                                    " New order " +
                                      _vm._s(not.deliveryOrderID) +
                                      " has been created by "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "item__notification-customer"
                                    },
                                    [_vm._v(" " + _vm._s(not.author.name))]
                                  )
                                ])
                              : _vm._e(),
                            not.status === "CANCELED"
                              ? _c("div", [
                                  _vm._v(
                                    "Order " +
                                      _vm._s(not.deliveryOrderID) +
                                      " has been canceled"
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              { staticClass: "item__notification-date" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.moment(not.date).format("D MMM h:mm a")
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "letter navbar__letter" }, [
            _vm._v(_vm._s(_vm.nameFirstLetter))
          ]),
          _c("div", { staticClass: "navbar__name" }, [
            _vm._v(_vm._s(_vm.$store.getters.user.name))
          ]),
          _c(
            "div",
            { staticClass: "navbar__actions" },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" }, on: { command: _vm.action } },
                [
                  _c("i", { staticClass: "ri-more-fill" }),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--actions",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          attrs: { command: "export" }
                        },
                        [
                          _c("i", { staticClass: "ri-file-chart-fill" }),
                          _vm._v(" Export ")
                        ]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          attrs: { command: "surcharge" }
                        },
                        [
                          _c("i", { staticClass: "ri-survey-fill" }),
                          _vm._v(" Surcharge ")
                        ]
                      ),
                      _vm.user.id === _vm.adminID
                        ? _c(
                            "el-dropdown-item",
                            {
                              staticClass: "item--edit",
                              attrs: { command: "invoices" }
                            },
                            [
                              _c("i", {
                                staticClass: "ri-money-dollar-circle-fill"
                              }),
                              _vm._v(" Finance ")
                            ]
                          )
                        : _vm._e(),
                      _vm.user.id === _vm.adminID
                        ? _c(
                            "el-dropdown-item",
                            {
                              staticClass: "item--edit",
                              attrs: { command: "operation" }
                            },
                            [
                              _c("i", { staticClass: "ri-lock-2-fill" }),
                              _vm._v(" Operation Status ")
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          attrs: { command: "Logout" }
                        },
                        [
                          _c("i", { staticClass: "ri-logout-circle-line" }),
                          _vm._v(" Logout ")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "navbar__mobile" },
        [
          _c(
            "el-dropdown",
            {
              attrs: { trigger: "click", placement: "bottom-start" },
              on: { "visible-change": _vm.showDropBg }
            },
            [
              _c("button", { staticClass: "icon-button" }, [
                _c("i", { staticClass: "ri-menu-line" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "el-popper-custom el-popper-custom--nav",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "el-dropdown-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav__item--mobile",
                          attrs: {
                            to: "/map",
                            "active-class": "nav__item--active"
                          }
                        },
                        [
                          _c("i", { staticClass: "ri-map-pin-fill" }),
                          _c("span", [_vm._v("Map")])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav__item--mobile",
                          attrs: {
                            to: "/deliveries",
                            "active-class": "nav__item--active"
                          }
                        },
                        [
                          _c("i", { staticClass: "ri-pin-distance-fill" }),
                          _c("span", [_vm._v("Deliveries")])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.role !== "driver"
                    ? _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav__item--mobile",
                              attrs: {
                                to: "/users",
                                "active-class": "nav__item--active"
                              }
                            },
                            [
                              _c("i", { staticClass: "ri-user-3-line" }),
                              _c("span", [_vm._v("Users")])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.role !== "driver"
                    ? _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav__item--mobile",
                              attrs: {
                                to: "/services",
                                "active-class": "nav__item--active"
                              }
                            },
                            [
                              _c("i", { staticClass: "ri-stack-fill" }),
                              _c("span", [_vm._v("Services")])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.role !== "driver"
                    ? _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav__item--mobile",
                              attrs: {
                                to: "/shifts",
                                "active-class": "nav__item--active"
                              }
                            },
                            [
                              _c("i", { staticClass: "ri-focus-line" }),
                              _c("span", [_vm._v("Shifts")])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.role !== "driver"
                    ? _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav__item--mobile",
                              attrs: {
                                to: "/dashboard",
                                "active-class": "nav__item--active"
                              }
                            },
                            [
                              _c("i", { staticClass: "ri-donut-chart-line" }),
                              _c("span", [_vm._v("Dashboard")])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-dropdown-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav__item--mobile",
                          attrs: {
                            to: "/orders",
                            "active-class": "nav__item--active"
                          }
                        },
                        [
                          _c("i", { staticClass: "ri-map-pin-fill" }),
                          _c("span", [_vm._v("Orders")])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.$route.meta.parent === "Users"
            ? _c("div", { staticClass: "navbar__title" }, [_vm._v("Users")])
            : _vm.$route.name === "AdminOrderPage"
            ? _c("div", { staticClass: "navbar__title" }, [
                _vm._v("Order / #" + _vm._s(_vm.$route.params.id))
              ])
            : _vm.$route.name === "ExportDeliveries"
            ? _c("div", { staticClass: "navbar__title" }, [
                _vm._v("Export Deliveries")
              ])
            : _c("div", { staticClass: "navbar__title" }, [
                _vm._v(_vm._s(_vm.path.name))
              ]),
          _vm.$route.meta.parent === "Users"
            ? _c(
                "div",
                [
                  _c("Multiselect", {
                    staticClass: "select-users",
                    attrs: {
                      options: _vm.links,
                      "track-by": "key",
                      label: "title",
                      "show-labels": false,
                      "allow-empty": false,
                      searchable: false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "singleLabel",
                          fn: function(props) {
                            return [
                              _c("div", { staticClass: "dropdown-link" }, [
                                _c("i", { class: props.option.icon }),
                                _c("span", [_vm._v(_vm._s(props.option.title))])
                              ])
                            ]
                          }
                        },
                        {
                          key: "option",
                          fn: function(props) {
                            return [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-link",
                                  attrs: {
                                    to: props.option.path + "/",
                                    "active-class": "dropdown-link--active"
                                  }
                                },
                                [
                                  _c("i", { class: props.option.icon }),
                                  _c("span", [
                                    _vm._v(_vm._s(props.option.title))
                                  ])
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2675016957
                    ),
                    model: {
                      value: _vm.link,
                      callback: function($$v) {
                        _vm.link = $$v
                      },
                      expression: "link"
                    }
                  })
                ],
                1
              )
            : _c("div"),
          _vm.hasBlock
            ? _c(
                "div",
                {
                  staticClass: "navbar__ostatus",
                  on: { click: _vm.changeBypassed }
                },
                [
                  _vm.lockBtn
                    ? _c("i", { staticClass: "ri-lock-unlock-line" })
                    : _c("i", { staticClass: "ri-lock-line" })
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "navbar__notification" },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click", placement: "bottom-end" },
                  on: { command: _vm.openNotification }
                },
                [
                  _c("div", { staticClass: "navbar__notification-button" }, [
                    _c("i", { staticClass: "ri-notification-2-fill" }),
                    _vm.notificatonCounter
                      ? _c(
                          "div",
                          { staticClass: "navbar__notification-number" },
                          [_vm._v(_vm._s(_vm.notificatonCounter))]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--notifications",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      !_vm.notifications.length
                        ? _c(
                            "el-dropdown-item",
                            { staticClass: "item__notification--missing" },
                            [_vm._v(" No notifications 😴 ")]
                          )
                        : _vm._e(),
                      _vm._l(_vm.notifications, function(not) {
                        return _c(
                          "el-dropdown-item",
                          {
                            key: not.id,
                            staticClass: "item__notification",
                            class: {
                              "item__notification--active": !not.wasRead
                            },
                            attrs: { command: not }
                          },
                          [
                            _c("div", {
                              staticClass: "dot",
                              class: {
                                "dot--green":
                                  not.status === "PENDING" || !not.wasRead,
                                "dot--red": not.status === "CANCELED",
                                "dot--yellow": not.wasRead
                              }
                            }),
                            not.status === "PENDING"
                              ? _c("div", [
                                  _vm._v(
                                    " New order " +
                                      _vm._s(not.deliveryOrderID) +
                                      " has been created by "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "item__notification-customer"
                                    },
                                    [_vm._v(" " + _vm._s(not.author.name))]
                                  )
                                ])
                              : _vm._e(),
                            not.status === "CANCELED"
                              ? _c("div", [
                                  _vm._v(
                                    "Order " +
                                      _vm._s(not.deliveryOrderID) +
                                      " has been canceled"
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              { staticClass: "item__notification-date" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.moment
                                      .utc(not.date)
                                      .format("D MMM h:mm a")
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "end",
              attrs: { trigger: "click" },
              on: { command: _vm.action }
            },
            [
              _c("button", { staticClass: "icon-button icon-button--round" }, [
                _c("i", { staticClass: "ri-more-fill" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "el-popper-custom",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "el-dropdown-item",
                    { staticClass: "item--edit", attrs: { command: "export" } },
                    [
                      _c("i", { staticClass: "ri-file-chart-fill" }),
                      _vm._v(" Export ")
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      staticClass: "item--edit",
                      attrs: { command: "surcharge" }
                    },
                    [
                      _c("i", { staticClass: "ri-survey-fill" }),
                      _vm._v(" Surcharge ")
                    ]
                  ),
                  _vm.user.id === _vm.adminID
                    ? _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          attrs: { command: "invoices" }
                        },
                        [
                          _c("i", {
                            staticClass: "ri-money-dollar-circle-fill"
                          }),
                          _vm._v(" Finance ")
                        ]
                      )
                    : _vm._e(),
                  _vm.user.id === _vm.adminID
                    ? _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          attrs: { command: "operation" }
                        },
                        [
                          _c("i", { staticClass: "ri-lock-2-fill" }),
                          _vm._v(" Operation Status ")
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-dropdown-item",
                    { staticClass: "item--edit", attrs: { command: "Logout" } },
                    [
                      _c("i", { staticClass: "ri-logout-circle-line" }),
                      _vm._v(" Logout ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showBg ? _c("div", { staticClass: "mobile-bg" }) : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navbar__left" }, [
      _c("div", { staticClass: "navbar__title" }, [_vm._v(" STS App ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }