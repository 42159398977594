var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "delivery__wrapper" }, [
    _c("div", { staticClass: "delivery__header" }, [
      _vm._m(0),
      _c("div", { staticClass: "header__right" }, [
        _c("div", { staticClass: "button button--icon" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "16",
                height: "16",
                viewBox: "0 0 16 16",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d:
                    "M10.11 3.49849C10.3302 3.27834 10.6871 3.27834 10.9073 3.49849L12.5017 5.09298C12.7218 5.31313 12.7218 5.67007 12.5017 5.89022L11.7045 6.68747L9.3128 4.29573L10.11 3.49849ZM8.5155 5.09275L10.9072 7.4845L5.89031 12.5015C5.78459 12.6072 5.64121 12.6666 5.49169 12.6666H3.89723C3.58588 12.6666 3.33349 12.4142 3.3335 12.1029V10.5083C3.3335 10.3588 3.39289 10.2155 3.49861 10.1097L8.5155 5.09275Z",
                  fill: "#172B4D"
                }
              })
            ]
          )
        ]),
        _c("div", { staticClass: "button button--icon" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "16",
                height: "16",
                viewBox: "0 0 16 16",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("g", { attrs: { opacity: "0.7" } }, [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d:
                      "M9.7619 3.85189C9.7619 3.56553 9.52739 3.33337 9.23809 3.33337H6.09524C5.80595 3.33337 5.57143 3.56553 5.57143 3.85189H4.52381C4.23452 3.85189 4 4.08404 4 4.37041C4 4.65678 4.23452 4.88893 4.52381 4.88893H10.8095C11.0988 4.88893 11.3333 4.65678 11.3333 4.37041C11.3333 4.08404 11.0988 3.85189 10.8095 3.85189H9.7619ZM10.8095 5.40745H4.52381V11.6297C4.52381 12.2024 4.99284 12.6667 5.57143 12.6667H9.7619C10.3405 12.6667 10.8095 12.2024 10.8095 11.6297V5.40745ZM6.09524 6.70374C6.09524 6.56056 6.2125 6.44449 6.35714 6.44449C6.50179 6.44449 6.61905 6.56056 6.61905 6.70374V11.3704C6.61905 11.5136 6.50179 11.6297 6.35714 11.6297C6.2125 11.6297 6.09524 11.5136 6.09524 11.3704V6.70374ZM8.71428 6.70374C8.71428 6.56056 8.83157 6.44449 8.97619 6.44449C9.12081 6.44449 9.23809 6.56056 9.23809 6.70374V11.3704C9.23809 11.5136 9.12081 11.6297 8.97619 11.6297C8.83157 11.6297 8.71428 11.5136 8.71428 11.3704V6.70374Z",
                    fill: "#172B4D"
                  }
                })
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header__left" }, [
      _c("div", { staticClass: "delivery__number" }, [
        _vm._v("Delivery # 122")
      ]),
      _c(
        "div",
        {
          staticClass:
            "delivery__status indicator indicator--opacity indicator--green"
        },
        [_vm._v("Delivered")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }