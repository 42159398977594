var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar__main" }, [
    _c("div", { staticClass: "sidebar__title" }, [
      _vm.data
        ? _c("span", [_vm._v("Edit - " + _vm._s(_vm.data.name))])
        : _c("span", [_vm._v("Add New Shift")])
    ]),
    _c("div", { staticClass: "sidebar__main-inner" }, [
      _c("div", { staticClass: "sidebar__row sidebar__row--last" }, [
        _c("div", { staticClass: "sidebar__row-title" }, [
          _vm._v("Basic Data")
        ]),
        _c(
          "div",
          { staticClass: "sidebar__row-main" },
          [
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.name,
                  "field__label--invalid":
                    _vm.$v.sendData.name.$dirty &&
                    !_vm.$v.sendData.name.required
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Shift Name")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.name.$model,
                      expression: "$v.sendData.name.$model"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Shift Name" },
                  domProps: { value: _vm.$v.sendData.name.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.name,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.dailyGoal,
                  "field__label--invalid":
                    (_vm.$v.sendData.dailyGoal.$dirty &&
                      !_vm.$v.sendData.dailyGoal.required) ||
                    (_vm.$v.sendData.dailyGoal.$dirty &&
                      !_vm.$v.sendData.dailyGoal.numeric)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Daily Goal")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.dailyGoal.$model,
                      expression: "$v.sendData.dailyGoal.$model"
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "#####",
                      expression: "'#####'"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Daily Goal" },
                  domProps: { value: _vm.$v.sendData.dailyGoal.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.dailyGoal,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.weeklyGoal,
                  "field__label--invalid":
                    (_vm.$v.sendData.weeklyGoal.$dirty &&
                      !_vm.$v.sendData.weeklyGoal.required) ||
                    (_vm.$v.sendData.weeklyGoal.$dirty &&
                      !_vm.$v.sendData.weeklyGoal.numeric)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Weekly Goal")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.weeklyGoal.$model,
                      expression: "$v.sendData.weeklyGoal.$model"
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "#####",
                      expression: "'#####'"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Weekly Goal" },
                  domProps: { value: _vm.$v.sendData.weeklyGoal.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.weeklyGoal,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled": _vm.sendData.monthlyGoal,
                  "field__label--invalid":
                    (_vm.$v.sendData.monthlyGoal.$dirty &&
                      !_vm.$v.sendData.monthlyGoal.required) ||
                    (_vm.$v.sendData.monthlyGoal.$dirty &&
                      !_vm.$v.sendData.monthlyGoal.numeric)
                }
              },
              [
                _c("div", { staticClass: "field__title" }, [
                  _vm._v("Monthly Goal")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.sendData.monthlyGoal.$model,
                      expression: "$v.sendData.monthlyGoal.$model"
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "#####",
                      expression: "'#####'"
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "text", placeholder: "Monthly Goal" },
                  domProps: { value: _vm.$v.sendData.monthlyGoal.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.sendData.monthlyGoal,
                        "$model",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            ),
            _c("div", { staticClass: "sidebar__row-title" }, [
              _vm._v("Shift Color")
            ]),
            _c("v-swatches", {
              attrs: { inline: "" },
              model: {
                value: _vm.sendData.color,
                callback: function($$v) {
                  _vm.$set(_vm.sendData, "color", $$v)
                },
                expression: "sendData.color"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "sidebar__footer" }, [
      _c("div", { staticClass: "sidebar__footer-inner" }, [
        _c(
          "div",
          {
            staticClass: "button button--fill",
            on: { click: _vm.createShift }
          },
          [
            _vm.data
              ? _c("span", [_vm._v("Save Changes")])
              : _c("span", [_vm._v("Create Shift")])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "button",
            on: {
              click: function($event) {
                return _vm.$root.$emit("ShowSidebar", null)
              }
            }
          },
          [_vm._v("Cancel")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }