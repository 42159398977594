var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__wrapper" }, [
    _c("div", { staticClass: "modal__title" }, [
      _vm._v("Change Fuel Surcharge")
    ]),
    _c(
      "form",
      {
        staticClass: "modal__form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.changeSurcharge($event)
          }
        }
      },
      [
        _c(
          "label",
          {
            staticClass: "field__label",
            class: { "field__label--filled": _vm.surcharge }
          },
          [
            _c("div", { staticClass: "field__title" }, [
              _vm._v("Fuel Surcharge %")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.localSurcharge,
                  expression: "localSurcharge",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "field__input",
              attrs: { type: "text", placeholder: "Fuel Surcharge" },
              domProps: { value: _vm.localSurcharge },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.localSurcharge = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ]
        ),
        _c(
          "button",
          {
            staticClass: "button button--fill modal__button",
            attrs: { type: "submit" }
          },
          [_vm._v(" Change Fuel Surcharge ")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }