var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar__wrapper" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "navbar__inner" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "navbar__right" },
          [
            _c(
              "router-link",
              {
                staticClass: "button",
                attrs: { to: "/directory", "active-class": "button--fill" }
              },
              [
                _c("i", { staticClass: "ri-user-2-fill" }),
                _vm._v(" Directory ")
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "button",
                attrs: { to: "/community", "active-class": "button--fill" }
              },
              [
                _c("i", { staticClass: "ri-chat-3-fill" }),
                _vm._v(" Community ")
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "button button--fill",
                class: { disabled: _vm.disableBtn },
                attrs: { to: "/public/new-order" }
              },
              [
                _c("i", { staticClass: "ri-map-pin-add-fill" }),
                _vm._v(" Book Order ")
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "button",
                attrs: { to: "/public/orders", "active-class": "button--fill" }
              },
              [
                _c("i", { staticClass: "ri-compass-fill" }),
                _c("span", [_vm._v("My Orders")])
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "button",
                attrs: { to: "/public/account", "active-class": "button--fill" }
              },
              [
                _c("i", { staticClass: "ri-account-circle-fill" }),
                _c("span", [_vm._v("My Account")])
              ]
            ),
            _c(
              "div",
              { staticClass: "navbar__notification" },
              [
                _c(
                  "el-dropdown",
                  {
                    attrs: { trigger: "click", placement: "bottom" },
                    on: { command: _vm.openNotification }
                  },
                  [
                    _c("div", { staticClass: "navbar__notification-button" }, [
                      _c("i", { staticClass: "ri-notification-2-fill" }),
                      _vm.notificatonCounter
                        ? _c(
                            "div",
                            { staticClass: "navbar__notification-number" },
                            [_vm._v(_vm._s(_vm.notificatonCounter))]
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass:
                          "el-popper-custom el-popper--notifications",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown"
                      },
                      [
                        !_vm.notifications.length
                          ? _c(
                              "el-dropdown-item",
                              { staticClass: "item__notification--missing" },
                              [_vm._v(" No notifications 😴 ")]
                            )
                          : _vm._e(),
                        _vm._l(_vm.notifications, function(not) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: not.id,
                              staticClass: "item__notification",
                              class: {
                                "item__notification--active": !not.wasRead
                              },
                              attrs: { command: not }
                            },
                            [
                              _c("div", {
                                staticClass: "dot",
                                class: {
                                  "dot--green":
                                    not.status === "APPROVED" || !not.wasRead,
                                  "dot--red": not.status === "DECLINED",
                                  "dot--yellow":
                                    (not.status === "WaitingForPayment" &&
                                      not.wasRead) ||
                                    not.status === "PENDING"
                                }
                              }),
                              not.status === "WaitingForPayment"
                                ? _c(
                                    "div",
                                    { staticClass: "item__notification-text" },
                                    [
                                      _vm._v(
                                        " Please pay order " +
                                          _vm._s(not.deliveryOrderID) +
                                          " "
                                      )
                                    ]
                                  )
                                : not.status === "PostComments"
                                ? _c(
                                    "div",
                                    { staticClass: "item__notification-text" },
                                    [
                                      _vm._v(
                                        " New comment on your post " +
                                          _vm._s(not.postID) +
                                          " "
                                      )
                                    ]
                                  )
                                : not.status === "NewBid"
                                ? _c(
                                    "div",
                                    { staticClass: "item__notification-text" },
                                    [
                                      _vm._v(
                                        " New bid in your post " +
                                          _vm._s(not.postID) +
                                          " "
                                      )
                                    ]
                                  )
                                : not.status === "BidAccepted"
                                ? _c(
                                    "div",
                                    { staticClass: "item__notification-text" },
                                    [
                                      _vm._v(
                                        " Your bid was accepted in your post " +
                                          _vm._s(not.postID) +
                                          " "
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "item__notification-text" },
                                    [
                                      _vm._v(
                                        " Your order " +
                                          _vm._s(not.deliveryOrderID) +
                                          " "
                                      ),
                                      not.status === "APPROVED"
                                        ? _c("span", [_vm._v("approved")])
                                        : not.status === "DECLINED"
                                        ? _c("span", [_vm._v("declined")])
                                        : _vm._e()
                                    ]
                                  ),
                              _c(
                                "span",
                                { staticClass: "item__notification-date" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .moment(not.date)
                                        .format("D MMM h:mm a")
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "navbar__account" }, [
              _c("div", { staticClass: "letter navbar__letter" }, [
                _vm._v(_vm._s(_vm.nameFirstLetter))
              ]),
              _c("div", { staticClass: "navbar__account-info" }, [
                _c("div", { staticClass: "navbar__account-title" }, [
                  _c("span", [_vm._v(_vm._s(_vm.userInfo.businessName))]),
                  _vm.userInfo.nickName
                    ? _c("span", [
                        _vm._v(" (" + _vm._s(_vm.userInfo.nickName) + ")")
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "navbar__account-subtitle" }, [
                  _c("span", [_vm._v(_vm._s(_vm.userInfo.fathersName))]),
                  _c("span", [_vm._v(" " + _vm._s(_vm.userInfo.firstName))]),
                  _c("span", [_vm._v(" " + _vm._s(_vm.userInfo.lastName))])
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "navbar__actions" },
              [
                _c(
                  "el-dropdown",
                  { attrs: { trigger: "click" }, on: { command: _vm.action } },
                  [
                    _c("i", { staticClass: "ri-more-fill" }),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "el-popper-custom el-popper--actions",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown"
                      },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            staticClass: "item--edit",
                            attrs: { command: "help" }
                          },
                          [
                            _c("i", { staticClass: "ri-question-fill" }),
                            _vm._v(" Help ")
                          ]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            staticClass: "item--edit",
                            attrs: { command: "Logout" }
                          },
                          [
                            _c("i", { staticClass: "ri-logout-circle-line" }),
                            _vm._v(" Logout ")
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "navbar__mobile" },
        [
          _c(
            "el-dropdown",
            {
              attrs: { trigger: "click", placement: "bottom-start" },
              on: { "visible-change": _vm.showDropBg }
            },
            [
              _c("button", { staticClass: "icon-button" }, [
                _c("i", { staticClass: "ri-menu-line" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "el-popper-custom el-popper-custom--nav",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "el-dropdown-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav__item--mobile",
                          attrs: {
                            to: "/directory",
                            "active-class": "nav__item--active"
                          }
                        },
                        [
                          _c("i", { staticClass: "ri-user-2-fill" }),
                          _c("span", [_vm._v("Directory")])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav__item--mobile",
                          attrs: {
                            to: "/community",
                            "active-class": "nav__item--active"
                          }
                        },
                        [
                          _c("i", { staticClass: "ri-chat-3-fill" }),
                          _c("span", [_vm._v("Community")])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav__item--mobile",
                          attrs: {
                            to: "/public/orders",
                            "active-class": "nav__item--active"
                          }
                        },
                        [
                          _c("i", { staticClass: "ri-map-pin-fill" }),
                          _c("span", [_vm._v("My Orders")])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav__item--mobile",
                          attrs: {
                            to: "/public/account",
                            "active-class": "nav__item--active"
                          }
                        },
                        [
                          _c("i", { staticClass: "ri-account-circle-fill" }),
                          _c("span", [_vm._v("My Account")])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.path.name === "PublicOrders"
            ? _c("div", { staticClass: "navbar__title" }, [_vm._v("Orders")])
            : _vm.path.name === "OrderPage"
            ? _c("div", { staticClass: "navbar__title" })
            : _vm.path.name === "DirectoryCustomer"
            ? _c("div", { staticClass: "navbar__title" })
            : _c("div", { staticClass: "navbar__title" }, [
                _vm._v(_vm._s(_vm.path.name))
              ]),
          _c(
            "div",
            { staticClass: "navbar__notification" },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click", placement: "bottom" },
                  on: { command: _vm.openNotification }
                },
                [
                  _c("div", { staticClass: "navbar__notification-button" }, [
                    _c("i", { staticClass: "ri-notification-2-fill" }),
                    _vm.notificatonCounter
                      ? _c(
                          "div",
                          { staticClass: "navbar__notification-number" },
                          [_vm._v(_vm._s(_vm.notificatonCounter))]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--notifications",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      !_vm.notifications.length
                        ? _c(
                            "el-dropdown-item",
                            { staticClass: "item__notification--missing" },
                            [_vm._v(" No notifications 😴 ")]
                          )
                        : _vm._e(),
                      _vm._l(_vm.notifications, function(not) {
                        return _c(
                          "el-dropdown-item",
                          {
                            key: not.id,
                            staticClass: "item__notification",
                            class: {
                              "item__notification--active": !not.wasRead
                            },
                            attrs: { command: not }
                          },
                          [
                            _c("div", {
                              staticClass: "dot",
                              class: {
                                "dot--green":
                                  not.status === "APPROVED" || !not.wasRead,
                                "dot--red": not.status === "DECLINED",
                                "dot--yellow":
                                  (not.status === "WaitingForPayment" &&
                                    not.wasRead) ||
                                  not.status === "PENDING"
                              }
                            }),
                            not.status === "WaitingForPayment"
                              ? _c(
                                  "div",
                                  { staticClass: "item__notification-text" },
                                  [
                                    _vm._v(
                                      " Please pay order " +
                                        _vm._s(not.deliveryOrderID) +
                                        " "
                                    )
                                  ]
                                )
                              : not.status === "PostComments"
                              ? _c(
                                  "div",
                                  { staticClass: "item__notification-text" },
                                  [
                                    _vm._v(
                                      " New comment on your post " +
                                        _vm._s(not.postID) +
                                        " "
                                    )
                                  ]
                                )
                              : not.status === "NewBid"
                              ? _c(
                                  "div",
                                  { staticClass: "item__notification-text" },
                                  [
                                    _vm._v(
                                      " New bid in your post " +
                                        _vm._s(not.postID) +
                                        " "
                                    )
                                  ]
                                )
                              : not.status === "BidAccepted"
                              ? _c(
                                  "div",
                                  { staticClass: "item__notification-text" },
                                  [
                                    _vm._v(
                                      " Your bid was accepted in your post " +
                                        _vm._s(not.postID) +
                                        " "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "item__notification-text" },
                                  [
                                    _vm._v(
                                      " Your order " +
                                        _vm._s(not.deliveryOrderID) +
                                        " "
                                    ),
                                    not.status === "APPROVED"
                                      ? _c("span", [_vm._v("approved")])
                                      : not.status === "DECLINED"
                                      ? _c("span", [_vm._v("declined")])
                                      : _vm._e()
                                  ]
                                ),
                            _c(
                              "span",
                              { staticClass: "item__notification-date" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.moment(not.date).format("D MMM h:mm a")
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "end",
              attrs: { trigger: "click" },
              on: { command: _vm.action }
            },
            [
              _c("button", { staticClass: "icon-button icon-button--round" }, [
                _c("i", { staticClass: "ri-more-fill" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "el-popper-custom",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "el-dropdown-item",
                    { staticClass: "item--edit", attrs: { command: "help" } },
                    [
                      _c("i", { staticClass: "ri-question-fill" }),
                      _vm._v(" Help ")
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    { staticClass: "item--edit", attrs: { command: "Logout" } },
                    [
                      _c("i", { staticClass: "ri-logout-circle-line" }),
                      _vm._v(" Logout ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showBg ? _c("div", { staticClass: "mobile-bg" }) : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navbar__left" }, [
      _c("div", { staticClass: "navbar__title" }, [_vm._v(" STS App ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }