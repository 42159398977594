var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__wrapper" }, [
    _c("div", { staticClass: "modal__header" }, [
      _c(
        "button",
        {
          staticClass: "icon-button icon-button--round",
          on: {
            click: function($event) {
              return _vm.$modal.hide("SearchPublicCustomerModal")
            }
          }
        },
        [_c("i", { staticClass: "ri-close-circle-fill" })]
      ),
      _c("div", { staticClass: "modal__title" }, [_vm._v("Choose Pickup")]),
      _c(
        "button",
        {
          staticClass: "button button--fill",
          class: { "button--fill--grey": !_vm.selectedCustomer },
          on: { click: _vm.done }
        },
        [_vm._v("Done")]
      )
    ]),
    _c("div", { staticClass: "modal__main" }, [
      _c("div", { staticClass: "modal-search__label" }, [
        _vm.type === "pickup"
          ? _c(
              "div",
              {
                staticClass: "icon-button icon-button--round icon-button--blue"
              },
              [_c("i", { staticClass: "ri-arrow-up-circle-line" })]
            )
          : _vm._e(),
        _vm.type === "dropOff"
          ? _c(
              "div",
              {
                staticClass:
                  "icon-button icon-button--round icon-button--orange"
              },
              [_c("i", { staticClass: "ri-arrow-down-circle-line" })]
            )
          : _vm._e(),
        _vm.type === "billing"
          ? _c(
              "div",
              {
                staticClass: "icon-button icon-button--round icon-button--blue"
              },
              [_c("i", { staticClass: "ri-money-dollar-circle-line" })]
            )
          : _vm._e(),
        _c("input", {
          ref: "searchField",
          staticClass: "modal-search__input",
          attrs: { type: "text", placeholder: "Start typing business name" },
          on: { input: _vm.search }
        })
      ]),
      _vm.customers.length
        ? _c(
            "div",
            { staticClass: "modal-search__list" },
            _vm._l(_vm.customers, function(customer) {
              return _c(
                "div",
                {
                  key: customer.id,
                  staticClass: "modal-search__item",
                  class: { "modal-search__item--active": customer.active },
                  on: {
                    click: function($event) {
                      return _vm.select(customer)
                    }
                  }
                },
                [
                  _vm._m(0, true),
                  _c("div", { staticClass: "modal-search__item-customer" }, [
                    _c("div", { staticClass: "modal-search__item-name" }, [
                      customer.fathersName
                        ? _c("span", [
                            _vm._v(_vm._s(customer.fathersName) + "’ ")
                          ])
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(customer.firstName) + " ")]),
                      _c("span", [
                        _vm._v(_vm._s(customer.middleInitial) + " ")
                      ]),
                      _c("span", [_vm._v(_vm._s(customer.lastName) + " ")]),
                      customer.nickName
                        ? _c("span", [
                            _vm._v("(" + _vm._s(customer.nickName) + ")")
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "modal-search__item-name" }, [
                      _c("span", [_vm._v(_vm._s(customer.businessName))])
                    ]),
                    _c("div", { staticClass: "modal-search__item-address" }, [
                      _c("span", [_vm._v("Address: ")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(customer.address) + " " + _vm._s(customer.city)
                        )
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-search__item-icon" }, [
      _c("i", { staticClass: "ri-checkbox-circle-fill" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }